<template>
  <div class="child-page-div">
    <div class="child-box-div">
      <div class="item-div">
        <label class="item-title-div">平台</label>
        <label>
          <select class="select" v-model="type">
            <option value="">全部</option>
            <option value="1">用户端</option>
            <option value="2">商家端</option>
            <option value="3">服务端</option>
          </select>
        </label>
      </div>
      <div class="item-div">
        <label class="item-title-div">标题</label>
        <label>
          <input type="text" v-model="title" maxlength="20" placeholder="请输入消息标题">
        </label>
      </div>
      <div class="item-div item-content-div">
        <label class="item-title-div">内容</label>
        <label>
          <textarea v-model="content" maxlength="200" placeholder="请输入消息内容"></textarea>
        </label>
      </div>
      <div class="btn-div">
        <input type="button" value="提交" @click="onSubmit">
      </div>
    </div>
  </div>
</template>

<script>
import { postSystemMsgAdd } from "../../common/api";

export default {
  name: "SystemMessage",
  data() {
    return {
      type: "",
      title: "",
      content: ""
    }
  },
  methods: {
    onSubmit() {
      let titleMsg = this.title.trim();
      let contentMsg = this.content.trim();
      if(titleMsg.length < 1 || titleMsg.length > 20){
        this.$layer.msg("标题只能输入1~20个字符");
        return;
      }
      if(contentMsg.length < 1 || contentMsg.length > 200){
        this.$layer.msg("内容只能输入1~200个字符");
        return;
      }

      postSystemMsgAdd({
        type: this.type,
        title: titleMsg,
        content: contentMsg
      }).then(() => {
        this.$layer.msg("操作成功");
      }).catch(error => {
        if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
          this.$layer.msg(error.data.msg);
        } else {
          this.$layer.msg("操作失败");
        }
      });
    }
  }
}
</script>

<style lang="less" scoped>
.child-page-div {
  .child-box-div {
    width: 360px;
    padding: 20px;
    border-radius: 5px;
    margin: 0 auto;
    background-color: white;
    .item-div {
      margin-bottom: 10px;
      .item-title-div {
        margin-right: 10px;
      }
      select {
        width: 110px;
        height: 30px;
        border: 1px solid #dcdcdc;
      }
      input {
        width: 290px;
        height: 30px;
        border: 1px solid #dcdcdc;
        padding: 0 5px;
      }
      textarea {
        width: 300px;
        height: 120px;
        padding: 5px;
        border: 1px solid #dcdcdc;
        resize: none;
      }
    }
    .item-content-div {
      display: flex;
    }
    .btn-div {
      text-align: center;
      margin-top: 20px;
      input {
        width: 80px;
        height: 30px;
        color: #f4f6f5;
        border: none;
        background: #4676c8;
      }
    }
  }
}
</style>